@use 'sass:math'; @import 'styles/mixins';
.previewContainer {
  width: 100%;
  overflow: hidden;
}

.preview {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color--border);
  border-radius: var(--border-radius--m);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  &.error {
    border-color: var(--color--danger--border);
  }
}

.content {
  display: flex;
  align-items: flex-start;
}

.delete {
  margin-left: var(--spacing--m);
}
