@use 'sass:math'; @import 'styles/mixins';
.label {
  display: inline-flex;
  align-items: center;
}

.icon {
  $size: 12px;

  width: $size !important;
  height: $size !important;
  margin-right: var(--spacing--s);
}
