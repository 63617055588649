@use 'sass:math'; @import 'styles/mixins';
.button {
  @include button-reset;

  box-sizing: content-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--status-bar--height);
  height: var(--status-bar--height);
  background-color: var(--status-bar--button--background);
  color: var(--status-bar--button--text);
  transition: var(--microanimation);
  cursor: pointer;

  &:focus,
  &:hover {
    background-color: var(--status-bar--button--background--hover);
  }

  & + & {
    border-left: 1px solid var(--color--border);
  }
}

.icon {
  $size: 16px;

  width: $size !important;
  height: $size !important;
}
