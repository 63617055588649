@use 'sass:math'; @import 'styles/mixins';
$searchIconSize: 16px;

.searchPanel {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: calc(-1 * var(--spacing--s));
  padding: var(--spacing--s);
}

.query {
  position: relative;
  flex: 0 0 auto;
}

.input {
  width: 100%;
  padding: var(--spacing--s) var(--spacing--m);
  padding-left: calc(var(--spacing--m) + var(--spacing--s) + #{$searchIconSize});
  background-color: var(--color--input--background);
  border-radius: var(--border-radius--m);
  border: 1px solid var(--color--border);
  font-size: var(--font-size--m);
  line-height: var(--line-height--m);
  color: var(--color--input--text);
  transition: var(--microanimation);

  &:focus,
  &:hover {
    border-color: var(--color--input--border--active);
  }

  &:focus {
    z-index: 1;
    outline: none;
  }

  &:focus-visible {
    box-shadow: var(--shadow--focus);
  }

  &::placeholder {
    color: var(--color--text--secondary);
  }
}

.inputIcon {
  position: absolute;
  top: 50%;
  left: var(--spacing--m);
  z-index: 1;
  width: $searchIconSize !important;
  height: $searchIconSize !important;
  color: var(--color--text--secondary);
  user-select: none;
  pointer-events: none;
  transform: translateY(-50%);
}

.results {
  @include custom-scrollbars();

  flex: 1;
  margin-top: var(--spacing--xl);
  overflow: auto;

  &:empty {
    display: none;
  }
}

.message {
  width: 100%;
  padding: var(--spacing--l);
  color: var(--color--text--primary);
  font-size: var(--font-size--m);
  line-height: var(--line-height--m);
  text-align: center;
}
