@use 'sass:math'; @import 'styles/mixins';
$iconSize: 24px;

:export {
  ICON_SIZE: $iconSize;
}

.result {
  display: flex;
  height: 54px;
  padding: var(--spacing--s) var(--spacing--l);
  font-size: 14px;
  line-height: 20px;

  &:before {
    display: none;
  }

  &:hover,
  &.selected {
    color: var(--color--default--text--hover);
    background-color: var(--color--default--background--hover);
  }

  @include all-link-states {
    text-decoration: none;
  }
}

.iconContainer {
  display: flex;
  align-items: flex-start;
  flex: 0 0 auto;
  margin-right: var(--spacing--m);
}

.icon {
  width: $iconSize;
  height: $iconSize;
}

.info {
  flex: 1;
  overflow: hidden;
}

.name {
  @include ellipsis;

  color: var(--color--text--primary);
  font-weight: bold;
}

.url {
  @include ellipsis;

  color: var(--color--text--secondary);
  font-size: var(--font-size--s);
  line-height: var(--line-height--s);
}
