@use 'sass:math'; @import 'styles/mixins';
.checkbox {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: var(--spacing--xl);
  border-radius: var(--border-radius--m);
  cursor: pointer;

  &:first-child {
    margin-top: 0;
  }

  &:focus-within {
    box-shadow: var(--shadow--focus);
  }
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  cursor: pointer;
}

.icon {
  flex: 0 0 auto;
  width: var(--font-size--l) !important;
  height: var(--font-size--l) !important;
  color: var(--color--primary--background);
}

.content {
  position: relative;
  top: 1px;
  flex: 1;
  margin-left: var(--spacing--m);
  font-size: var(--font-size--m);
  line-height: var(--line-height--m);
}
