@use 'sass:math'; @import 'styles/mixins';
.input {
  width: 100%;
  padding: var(--spacing--s) var(--spacing--m);
  background-color: var(--color--input--background);
  border: 1px solid var(--color--input--border);
  border-radius: var(--border-radius--m);
  font-family: var(--font-family);
  font-size: var(--font-size--m);
  line-height: var(--line-height--m);
  color: var(--color--input--text);
  transition: var(--microanimation);
  // do not animate "height" or "width"
  transition-property: background-color, color, border-color, box-shadow;
  resize: vertical;

  &:focus,
  &:hover {
    border-color: var(--color--input--border--active);
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    box-shadow: var(--shadow--focus);
  }

  &[disabled] {
    border-color: var(--color--input--border--disabled);
    background-color: var(--color--input--background--disabled);
    color: var(--color--input--text--disabled);
  }

  &::placeholder {
    color: var(--color--text--secondary);
  }
}
