@use 'sass:math'; @import 'styles/mixins';
.link {
  @include all-link-states {
    text-decoration: none;

    &.default {
      color: var(--color--text--primary);
    }

    &.danger {
      color: var(--color--error--text);
    }
  }
}
