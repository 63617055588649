@use 'sass:math'; @import 'styles/mixins';
@import 'styles/animations';

.cue {
  $size: 32px;

  @include button-reset;

  position: relative;
  width: $size;
  height: $size;
  background: radial-gradient(var(--color--brand--red) 0, transparent 100%);
  border-radius: 50%;
  box-shadow: var(--shadow);
  cursor: pointer;

  &:before {
    $size: 10px;

    position: absolute;
    top: -$size;
    right: -$size;
    bottom: -$size;
    left: -$size;
    background: radial-gradient(var(--color--brand--red) 0, transparent 100%);
    border-radius: 50%;
    animation: fade 1000ms infinite linear forwards;
    content: ' ';
    pointer-events: none;
  }
}
