@use 'sass:math'; @import 'styles/mixins';
@import 'utils';

$status-bar--height: 40px;
$status-bar--icon--size: 16px;
$sidebar--width: 64px;
$sidebar--width--wide: 280px;
$sidebar--item--height: 42px;
$sidebar--websktop--icon--size: 24px;

:root {
  // font family
  --font-family: system-ui, sans-serif;
  --font-family--code: ui-monospace, 'Cascadia Code', 'Source Code Pro', Menlo, Consolas,
  'DejaVu Sans Mono', monospace;
  --font-family--heading: var(--font-family);

  // font size
  --font-size--xs: 10px;
  --font-size--s: 12px;
  --font-size--m: 16px;
  --font-size--l: 20px;
  --font-size--xl: 24px;
  --font-size--xxl: 32px;
  --font-size--xxxl: 48px;

  // line height
  --line-height--xs: 16px;
  --line-height--s: 18px;
  --line-height--m: 24px;
  --line-height--l: 28px;
  --line-height--xl: 34px;
  --line-height--xxl: 40px;
  --line-height--xxxl: 60px;

  // colors
  --color--brand--blue: #1a172c;
  --color--brand--red: #fe3533;
  --color--text: #575757;
  --color--text--primary: #333;
  --color--text--secondary: #8e8e93;
  --color--text--disabled: #737578; //#c5c6c7;
  --color--focus: #0b77ea;
  --color--highlight: orange;
  --color--background: #f7f7fa;
  --color--websktop-background: #d5ecfb;
  --color--border: #e5e5e5;
  --color--border--dark: #b5b5b5;

  --color--link: #1675e0;
  --color--link--active: #004299;
  --color--link--hover: #0a5dc2;
  --color--error--background: #fff2f2;
  --color--error--text: #f44336;
  --color--info--background: #f0f9ff;
  --color--info--text: #2196f3;
  --color--success--background: #eeffed;
  --color--success--text: #4caf50;
  --color--warning--background: #fffaf2;
  --color--warning--text: #ffb300;

  --color--primary--background: #3498ff;
  --color--primary--background--active: #1675e0;
  --color--primary--background--disabled: var(--color--primary--background);
  --color--primary--background--hover: #2589f5;
  --color--primary--border: #0b77ea;
  --color--primary--text: #fff;
  --color--primary--text--active: var(--color--primary--text);
  --color--primary--text--disabled: var(--color--primary--text);
  --color--primary--text--hover: var(--color--primary--text);

  --color--default--background: #f7f7fa;
  --color--default--background--active: #d9d9d9;
  --color--default--background--disabled: #f7f7fa;
  --color--default--background--hover: #e5e5ea;
  --color--default--border: #dedede;
  --color--default--text: #575757;
  --color--default--text--active: #272c36;
  --color--default--text--disabled: #c5c6c7;
  --color--default--text--hover: var(--color--default--text);

  --color--danger--background: #f44336;
  --color--danger--background--active: #d62915;
  --color--danger--background--disabled: var(--color--danger--background);
  --color--danger--background--hover: #eb3626;
  --color--danger--border: #da1001;
  --color--danger--text: #fff;
  --color--danger--text--active: var(--color--danger--text);
  --color--danger--text--disabled: var(--color--danger--text);
  --color--danger--text--hover: var(--color--danger--text);

  --color--input--text: var(--color--text);
  --color--input--text--disabled: var(--color--text--disabled);
  --color--input--background: #ffffff;
  --color--input--background--disabled: #f7f7fa;
  --color--input--border: var(--color--border);
  --color--input--border--active: #3498ff;
  --color--input--border--disabled: var(--color--input--border);

  --color--card--background: #ffffff;

  --color--panel--background: #ffffff;
  --color--panel--border: var(--color--border);
  --color--panel--text: #575757;

  --color--hr--border: var(--color--border);

  --color--code--background: var(--color--input--background--disabled);
  --color--code--text: #e01e5a;

  // shadows
  --shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  --shadow--null: 0px 0px 10px 0px rgba(0, 0, 0, 0);
  --shadow--drop: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
  --shadow--focus: 0 0 0 3px var(--color--focus);

  // borders
  --border-radius--s: 2px;
  --border-radius--m: 4px;
  --border-radius--l: 8px;

  // spacing
  --spacing--base: 4px;
  --spacing--xs: calc(1 * var(--spacing--base));
  --spacing--s: calc(2 * var(--spacing--base));
  --spacing--m: calc(3 * var(--spacing--base));
  --spacing--l: calc(4 * var(--spacing--base));
  --spacing--xl: calc(6 * var(--spacing--base));
  --spacing--xxl: calc(8 * var(--spacing--base));
  --spacing--xxxl: calc(18 * var(--spacing--base));

  // z-index
  --z-index--context-menu-overlay: var(--z-index--modal-overlay);
  --z-index--popup-overlay: var(--z-index--modal-overlay);
  --z-index--dropdown-button-overlay: var(--z-index--modal-overlay);
  --z-index--header: 100;
  --z-index--modal: calc(var(--z-index--modal-overlay) + 1);
  --z-index--modal-overlay: 999;
  --z-index--notification: calc(var(--z-index--modal) + 1);
  --z-index--tooltip: calc(var(--z-index--notification) + 1);

  // animations
  --microanimation: all var(--microanimation--duration) linear;
  --microanimation--duration: 100ms;

  // sidebar
  --sidebar--width: #{$sidebar--width};
  --sidebar--width--wide: #{$sidebar--width--wide};
  --sidebar--background: #1c223d;
  --sidebar--background--active: #2c3353;
  --sidebar--background--hover: #2c3353;
  --sidebar--background--focus: #212849;
  --sidebar--text: #ffffff;
  --sidebar--item--height: #{$sidebar--item--height};
  --sidebar--item--icon--size: 20px;
  --sidebar--websktop--icon--size: #{$sidebar--websktop--icon--size};

  // header
  --header--text: #eeeeee;
  --header--text--active: #ffffff;

  // footer
  --footer--background: var(--color--brand--blue);
  --footer--text: #eeeeee;
  --footer--text--active: var(--color--brand--red);
  --footer--text--heading: #827ea0;
  --footer--text--hover: var(--color--brand--red);

  // tooltips
  --tooltip--background: #272c36;
  --tooltip--text: #ffffff;

  // notifications
  --notification--background: #ffffff;
  --notification--text: var(--color--text);

  // grid layout
  --grid-layout--cell--width: 76px; // same as ICON_WIDTH
  --grid-layout--cell--height: calc(
    var(--grid-layout--image-size) + var(--grid-layout--image-spacing) +
      var(--grid-layout--ellipsis-lines) * var(--grid-layout--line-height)
  ); // same as ICON_HEIGHT
  --grid-layout--background: transparent;
  --grid-layout--background--hover: rgba(188, 222, 245, 0.3); // transparentify(#ebf5fc, 0.3);
  --grid-layout--background--selected: rgba(125, 185, 235, 0.3); // transparentify(#d8eaf9, 0.3);
  // transparentify(#c4e0f6, 0.3);
  --grid-layout--background--selected-hover: rgba(58, 152, 225, 0.3);
  --grid-layout--background--selected--inactive: rgba(
    125,
    185,
    235,
    0.1
  ); // transparentify(#d8eaf9, 0.1);

  --grid-layout--border: #646464;
  --grid-layout--font-size: var(--font-size--s);
  --grid-layout--line-height: var(--line-height--xs);
  --grid-layout--image-size: 48px; // same as IMAGE_SIZE
  --grid-layout--image-spacing: var(--spacing--xs);
  --grid-layout--spacing--column: var(--spacing--xs); // same as SPACING_COLUMN
  --grid-layout--spacing--row: var(--spacing--l); // same as SPACING_ROW
  --grid-layout--ellipsis-lines: 2;
  --grid-layout--input--background: #ffffff;
  --grid-layout--input--text: #000000;

  // menu
  --menu--background: #ffffff;
  --menu--background--active: #e4e4e4;
  --menu--background--hover: #eeeeee;
  --menu--border: 1px solid #ddd;
  --menu--border-radius: var(--border-radius--m);

  // modal
  --modal--overlay: rgba(0, 0, 0, 0.5);
  --modal--max-width: 500px;

  // status bar
  --status-bar--background: var(--sidebar--background);
  --status-bar--background--active: var(--sidebar--background--active);
  --status-bar--background--hover: var(--sidebar--background--hover);
  --status-bar--border-color: rgba(255, 255, 255, 0.3);
  --status-bar--height: #{$status-bar--height};
  --status-bar--button--background: var(--sidebar--background);
  --status-bar--button--background--active: var(--sidebar--background--active);
  --status-bar--button--background--hover: var(--sidebar--background--hover);
  --status-bar--button--text: var(--sidebar--text);
  --status-bar--icon--size: #{$status-bar--icon--size};
  --status-bar--text: #ffffff;
  --status-bar--shadow--color: var(--status-bar--border-color);

  --mass-selection--background: rgba(22, 115, 208, 0.3); // in stylus: transparentify(#b9d5f1, 0.3)
  --mass-selection--border: #0078d7;

  --page--max-width: 800px;

  --chat-button--height: 60px;
}

:export {
  SIDEBAR_WIDTH: strip-unit($sidebar--width);
  SIDEBAR_WIDTH_WIDE: strip-unit($sidebar--width--wide);
  SIDEBAR_WEBSKTOP_ICON_SIZE: strip-unit($sidebar--websktop--icon--size);
  STATUS_BAR_HEIGHT: strip-unit($status-bar--height);
  STATUS_BAR_ICON_SIZE: strip-unit($status-bar--icon--size);
}
