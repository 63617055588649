@use 'sass:math'; @import 'styles/mixins';
@import 'styles/mixins';

.iconInput {
  @include button-reset;

  cursor: pointer;
}

.popup {
  display: flex;
  box-shadow: var(--shadow--drop);
  border-radius: var(--border-radius--l);
}

.option,
.iconInput {
  @include button-reset;

  --size: 80px;

  position: relative;
  width: var(--size);
  height: var(--size);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color--default--border);
  border-radius: var(--border-radius--l);
  background-color: var(--color--default--background);
  color: var(--color--default--text);
  cursor: pointer;

  &:active {
    top: 1px;
  }

  &:focus-within {
    box-shadow: var(--shadow--focus);
    outline: none;
    z-index: 1;
  }

  &:hover {
    background-color: var(--color--default--background--hover);
    color: var(--color--default--text--hover);
  }

  &:active {
    background-color: var(--color--default--background--active);
    color: var(--color--default--text--active);
  }
}

.iconInput {
  --size: 64px;
}

.option {
  --size: 80px;

  border-left: none;
  border-radius: 0;

  &:first-child {
    border-left: 1px solid var(--color--default--border);
    border-top-left-radius: var(--border-radius--l);
    border-bottom-left-radius: var(--border-radius--l);
  }

  &:last-child {
    border-top-right-radius: var(--border-radius--l);
    border-bottom-right-radius: var(--border-radius--l);
  }
}

.icon {
  --size: 24px;

  width: var(--size);
  height: var(--size);
  margin-bottom: var(--spacing--s);

  &.default {
    color: var(--color--error--text);
  }

  &.upload {
    color: var(--color--info--text);
  }
}

.emoji {
  display: flex;
  align-items: center;
  justify-content: center;
}

.label {
  font-size: var(--font-size--s);
}

.fileInput {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  background-color: var(--color--input--background);

  &[disabled] {
    cursor: not-allowed;
    background-color: var(--color--input--background--disabled);
  }
}
