@use 'sass:math'; @import 'styles/mixins';
@import 'styles/animations';

.globalCopyCursor * {
  cursor: copy;
}

.globalNoDropCursor * {
  cursor: no-drop;
}

.breadcrumb {
  --border--size: calc(var(--status-bar--height) / 2);
  --sqrt--2: 1.43; // ~1.4142135, but webkit rounds 1.43 better
  --diagonal--size: calc(var(--border--size) / var(--sqrt--2));

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--status-bar--height);
  padding-left: var(--spacing--l);
  padding-right: var(--spacing--l);
  background-color: var(--status-bar--button--background);
  transition: var(--microanimation);
  transition-property: background-color, color;

  & + & {
    padding-left: calc(var(--spacing--l) + var(--border--size));
  }

  @include all-link-states {
    color: var(--status-bar--button--text);
    text-decoration: none;
  }


  &:not(.current) {
    &:active {
      top: 1px;
    }

    &:focus,
    &:hover {
      background-color: var(--status-bar--button--background--hover);

      &:after {
        border-top-color: var(--status-bar--button--background--hover);
        border-right-color: var(--status-bar--button--background--hover);
      }
    }
  }

  &.canDrop {
    color: var(--color--success--text);

    &.isOver {
      background-color: var(--color--success--background);

      &:after {
        border-top-color: var(--color--success--background);
        border-right-color: var(--color--success--background);
      }
    }
  }

  &.current:not(:only-child) {
    background-color: var(--status-bar--button--background--active);
    border-bottom: 2px solid var(--color--highlight);
    border-top: 2px solid transparent;

    &:after {
      border-top-color: var(--status-bar--button--background--active);
      border-right-color: var(--status-bar--button--background--active);
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: calc(100% - var(--diagonal--size));
    z-index: 1;
    width: 0;
    height: 0;
    border: var(--diagonal--size) solid var(--status-bar--button--background);
    border-left-color: transparent;
    border-bottom-color: transparent;
    box-shadow: 1px -1px 0px 0px var(--status-bar--shadow--color);
    transform: rotate(45deg);
    transition: var(--microanimation);
  }

  &:last-child {
    &:after {
      display: none;
    }
  }
}
