@use 'sass:math'; @import 'styles/mixins';
.infoIcon {
  position: relative;
  top: 2px;
  margin-left: var(--spacing--s);
}

.signUp {
  margin-left: var(--spacing--l);
}
