@use 'sass:math'; @import 'styles/mixins';
@import 'styles/animations';

.websktopLogoIcon {
  &.animated,
  &.currentColor {
    path {
      fill: currentColor;
    }
  }

  &.animated {
    @include fancy-text(
      $animationSteps: 48,
      $animationElementsCount: 14,
      $delayBetweenLetters: 0.2
    );
  }

  &.brandWhite {
    .ray {
      fill: white;
    }
  }
}
