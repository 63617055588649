@use 'sass:math'; @import 'styles/mixins';
* {
  box-sizing: border-box;
}

body {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  font-family: var(--font-family);
  font-size: var(--font-size);
  line-height: var(--line-height);
  background-color: var(--color--background);
  background-image: url('./assets/background.png');
  color: var(--color--text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none !important;
  overflow: hidden;

  @media (hover: none) {
    user-select: none;
  }
}

h1 {
  margin: var(--spacing--xl) 0;
  font-size: var(--font-size--xxl);
  line-height: var(--line-height--xxl);
}

h2 {
  margin: var(--spacing--l) 0;
  font-size: var(--font-size--xl);
  line-height: var(--line-height--xl);
}

h3 {
  margin: var(--spacing--m) 0;
  font-size: var(--font-size--l);
  line-height: var(--line-height--l);
}

h4,
h5,
h6 {
  margin: var(--spacing--m) 0;
  font-size: var(--font-size--m);
  line-height: var(--line-height--m);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family--heading);
  color: var(--color--text--primary);

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

hr {
  position: relative;
  margin: var(--spacing--xxxl) auto;
  height: 1px;
  border: none;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.15),
    rgba(0, 0, 0, 0)
  );
}

img {
  display: block;
}

p {
  margin: var(--spacing--m) 0;
  font-family: var(--font-family);
  font-size: var(--font-size--m);
  line-height: var(--line-height--m);
  color: var(--color--text);

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

ul,
ol {
  margin: var(--spacing--m) 0;
  padding-inline-start: 2em;
  color: var(--color--text);
}

li + li {
  margin-top: var(--spacing--s);
}

a {
  -webkit-user-select: none !important;

  &:focus-visible {
    outline: none;
  }
}

code {
  padding: 2px;
  border: 1px solid var(--color--border);
  border-radius: var(--border-radius--s);
  background-color: var(--color--code--background);
  color: var(--color--code--text);
  font-family: var(--font-family--code);
  font-variant-ligatures: none;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: normal;
  tab-size: 2;
}

mark {
  background-color: transparent;
  color: var(--color--link);
}

#root {
  height: 100%;
  overflow-y: auto;
}
