@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes reveal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@mixin pop-up {
  transform-origin: 50% 0;
  animation: pop-up var(--microanimation--duration) linear forwards;

  @keyframes pop-up {
    0% {
      opacity: 0;
      transform: scaleY(0.8);
    }

    100% {
      opacity: 1;
      transform: scaleY(1);
    }
  }
}

@mixin pop-out {
  transform-origin: 50% 0;
  animation: pop-out var(--microanimation--duration) linear forwards;
  pointer-events: none;
  user-select: none;

  @keyframes pop-out {
    0% {
      opacity: 1;
      transform: scaleY(1);
    }

    100% {
      opacity: 0;
      transform: scaleY(0.8);
      overflow: hidden;
      display: none;
    }
  }
}

@keyframes breathe {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes fade {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

@mixin fancy-text(
  $animationSteps: 20,
  $animationDuration: 50,
  $animationElement: path,
  $animationElementsCount: 100,
  $delayBetweenLetters: 0.2
) {
  #{$animationElement} {
    animation-name: fancy-text;
    animation-duration: #{$animationDuration}s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-fill-mode: forwards;
  }

  @keyframes fancy-text {
    @for $i from 0 through $animationSteps {
      #{percentage($i * math.div(1, $animationSteps))} {
        color: hsla(random(365), 60%, 60%, 1);
      }
    }
  }

  $totalDelayTime: $animationElementsCount * $delayBetweenLetters;

  @for $i from 1 through $animationElementsCount {
    #{$animationElement}:nth-of-type(#{$i}) {
      animation-delay: #{($i * $delayBetweenLetters) - $totalDelayTime}s;
    }
  }
}

@keyframes dash {
  from {
    stroke-dashoffset: 100%;
  }

  to {
    stroke-dashoffset: 0%;
  }
}
