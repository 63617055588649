@use 'sass:math'; @import 'styles/mixins';
.link {
  position: relative;

  &.button {
    border-width: 1px;
    border-style: solid;

    @include all-link-states {
      text-decoration: none;
    }
  }

  &:not(.button) {
    color: var(--color--link);

    &:active {
      color: var(--color--link--active);
    }

    &:before {
      $padding: 2px;

      content: ' ';
      position: absolute;
      inset: -$padding;
      padding: $padding;
      transition: var(--microanimation);
      border-radius: var(--border-radius--m);
      pointer-events: none;
    }

    &:focus,
    &:hover {
      color: var(--color--link--hover);
      outline: none;
    }

    &:focus-visible {
      &:before {
        box-shadow: var(--shadow--focus);
      }
    }
  }
}
