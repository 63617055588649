@use 'sass:math'; @import 'styles/mixins';
@import 'styles/animations';

.button {
  @include button-reset;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: calc(var(--font-size--m) + 2 * var(--spacing--s));
  min-height: calc(var(--font-size--m) + 2 * var(--spacing--s));
  padding: var(--spacing--s) var(--spacing--m);
  border: 1px solid transparent;
  border-radius: var(--border-radius--m);
  font-size: var(--font-size--m);
  line-height: var(--line-height--m);
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  transition: var(--microanimation);

  &.inline {
    display: inline-flex;
  }

  &.small {
    padding: var(--spacing--xs) var(--spacing--s);
    font-size: var(--font-size--s);
  }

  &.large {
    padding: var(--spacing--l) var(--spacing--xxl);
    font-size: var(--font-size--xl);
    font-weight: bold;
  }

  &[disabled] {
    cursor: not-allowed;
  }

  &:focus-visible {
    box-shadow: var(--shadow--focus);
  }

  &.compact {
    padding: var(--spacing--s);

    .icon {
      margin-right: 0;
    }
  }

  &.danger {
    border-color: var(--color--danger--border);
    background-color: var(--color--danger--background);
    color: var(--color--danger--text);

    &:hover {
      background-color: var(--color--danger--background--hover);
      color: var(--color--danger--text--hover);
    }

    &:active {
      background-color: var(--color--danger--background--active);
      color: var(--color--danger--text--active);
    }

    &[disabled] {
      background-color: var(--color--danger--background--disabled);
      color: var(--color--danger--text--disabled);
      opacity: 0.3;
    }
  }

  &.default {
    border-color: var(--color--default--border);
    background-color: var(--color--default--background);
    color: var(--color--default--text);

    &:hover {
      background-color: var(--color--default--background--hover);
      color: var(--color--default--text--hover);
    }

    &:active {
      background-color: var(--color--default--background--active);
      color: var(--color--default--text--active);
    }

    &[disabled] {
      background-color: var(--color--default--background--disabled);
      color: var(--color--default--text--disabled);
    }
  }

  &.primary {
    border-color: var(--color--primary--border);
    background-color: var(--color--primary--background);
    color: var(--color--primary--text);

    &:hover {
      background-color: var(--color--primary--background--hover);
      color: var(--color--primary--text--hover);
    }

    &:active {
      background-color: var(--color--primary--background--active);
      color: var(--color--primary--text--active);
    }

    &[disabled] {
      background-color: var(--color--primary--background--disabled);
      color: var(--color--primary--text--disabled);
      opacity: 0.3;
    }
  }
}

.icon {
  margin-right: var(--spacing--s);
}

.loader {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: inherit;
}
