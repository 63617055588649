@use 'sass:math'; @import 'styles/mixins';
.content {
  position: relative;
  user-select: none;

  &:focus-visible {
    outline: none;
  }

  &:not(:focus-within) {
    --grid-layout--background--selected: var(--grid-layout--background--selected--inactive);
  }
}

.selecting {
  pointer-events: none;
}

.selection,
.dropTarget,
.dragLayer,
.dragLayerItems {
  position: absolute;
  pointer-events: none;
  user-select: none;
}

.selection {
  background-color: var(--mass-selection--background);
  border: 1px solid var(--mass-selection--border);
  z-index: 2;
}

.dropTarget {
  display: none;
  z-index: 3;
}

.dragLayer {
  z-index: 4;
  overflow: hidden;
}

.dragLayerItems {
  z-index: 5;
}

.sizer {
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  visibility: hidden;
  pointer-events: none;
  user-select: none;
}

.contextMenuCue {
  position: absolute;
  top: 180px;
  left: 50%;
  z-index: 5;
  transform: translateX(-50%);
}
