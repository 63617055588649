@use 'sass:math'; @import 'styles/mixins';
$arrow-size: 4px;

:export {
  ARROW_SIZE: $arrow-size;
  OFFSET: 2px;
  PREVENT_OVERFLOW: 10px;
}

.tooltip {
  --color--text: var(--tooltip--text);

  padding: var(--spacing--s) var(--spacing--m);
  box-shadow: var(--shadow);
  border-radius: var(--border-radius--m);
  background-color: var(--tooltip--background);
  color: var(--tooltip--text);
  font-size: var(--font-size--s);
  line-height: var(--line-height--s);
  z-index: var(--z-index--tooltip);

  &.top {
    .arrow {
      bottom: -$arrow-size;

      &:after {
        left: 0;
        bottom: 0;
        border-top-color: var(--tooltip--background);
        border-bottom: none;
      }
    }
  }

  &.right {
    .arrow {
      left: -$arrow-size;

      &:after {
        left: 0;
        top: 0;
        border-right-color: var(--tooltip--background);
        border-left: none;
      }
    }
  }

  &.bottom {
    .arrow {
      top: -$arrow-size;

      &:after {
        top: 0;
        left: 0;
        border-bottom-color: var(--tooltip--background);
        border-top: none;
      }
    }
  }

  &.left {
    .arrow {
      right: -$arrow-size;

      &:after {
        right: 0;
        top: 0;
        border-left-color: var(--tooltip--background);
        border-right: none;
      }
    }
  }
}

.arrow {
  position: absolute;
  width: 2 * $arrow-size;
  height: 2 * $arrow-size;

  &:after {
    content: ' ';
    position: absolute;
    height: 0;
    width: 0;
    pointer-events: none;
    border: $arrow-size solid transparent;
  }
}
