@use 'sass:math'; @import 'styles/mixins';
@import 'styles/animations';

.container {
  position: fixed;
  inset: 0;
  z-index: var(--z-index--notification);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  pointer-events: none;
}

.notifications {
  $width: 450px;

  margin: 5vh var(--spacing--l);
  padding: var(--spacing--xs);
  max-width: $width;
  width: $width;
}

.notification {
  @include pop-up;

  margin: var(--spacing--l) 0;
  pointer-events: auto;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.hidden {
    @include pop-out;

    & ~ .notification {
      margin-top: 0 !important;
    }
  }
}
