@use 'sass:math'; @import 'styles/mixins';
.colors {
  $color-size: 50px;

  display: flex;
  flex-wrap: wrap;
  margin: calc(-1 * var(--spacing--xs));

  @include media('<xs') {
    grid-template-columns: repeat(5, $color-size);
  }
}

.color {
  margin: var(--spacing--xs);
}
