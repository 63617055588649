@import 'include-media';

$breakpoints: (
  xs: 480px,
  s: 768px,
  m: 992px,
  l: 1200px,
  xl: 1400px,
);

@mixin force-gpu-rendering {
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000;
  transform: translate3d(0, 0, 0);
  transform: translateZ(0);
}

@mixin button-reset {
  padding: 0;
  border: none;
  outline: none !important;
  font: inherit;
  color: inherit;
  background: none;
}

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin no-hover {
  @media (hover: none) {
    @content;
  }
}

@mixin all-link-states {
  &,
  &:active,
  &:focus,
  &:hover,
  &:visited {
    @content;
  }
}

@mixin custom-scrollbars {
  $size: 6px;
  $color--track: transparent;
  $color--thumb: var(--color--text);
  $color--thumb--active: var(--color--text--secondary);

  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: $color--thumb;
    border: none;
    border-radius: 0;
  }

  &:active,
  &:focus,
  &:hover {
    &::-webkit-scrollbar-thumb {
      background: $color--thumb--active;
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border: none;
    border-radius: 0;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}
