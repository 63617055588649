@use 'sass:math'; @import 'styles/mixins';
$icon-size: 12px;

.item {
  @include button-reset;

  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: var(--spacing--xs) var(--spacing--m);
  font-size: var(--font-size--m);
  line-height: var(--line-height--m);
  text-align: left;
  cursor: pointer;
  user-select: none;

  &:focus-within {
    position: relative;
    z-index: 1;
    box-shadow: var(--shadow--focus);
  }

  &:hover {
    background-color: var(--menu--background--hover);
  }

  &.pressed,
  &:active {
    background-color: var(--menu--background--active);

    .content {
      position: relative;
      top: 1px;
    }

    .icon {
      top: 0;
    }
  }

  &[disabled] {
    pointer-events: none;
    cursor: not-allowed !important;
    color: var(--color--text--disabled) !important;

    .addon {
      opacity: 0.6;
    }
  }

  &.default {
    color: var(--color--text--primary);

    .icon {
      color: var(--color--text);
    }

    &[disabled] {
      .icon {
        color: var(--color--text--disabled) !important;
      }
    }
  }

  &.danger {
    color: var(--color--error--text);
  }
}

.icon {
  position: relative;
  top: -1px;
  flex: 0 0 auto;
  width: $icon-size !important;
  height: $icon-size !important;
  margin-right: var(--spacing--s);
  margin-top: calc((var(--line-height--m) - #{$icon-size}) / 2);
}

.content {
  @include ellipsis;

  flex: 1;

  // no icon
  &:first-child {
    margin-left: calc(var(--spacing--s) + #{$icon-size});
  }
}

.addon {
  flex: 0 0 auto;
  margin-left: var(--spacing--s);
}
