@use 'sass:math'; @import 'styles/mixins';
.linkButton {
  @include button-reset;

  position: relative;
  text-decoration: underline;
  color: var(--color--link);
  cursor: pointer;
  border-radius: var(--border-radius--m);

  &:before {
    $padding: 2px;

    content: ' ';
    position: absolute;
    inset: -$padding;
    padding: $padding;
    transition: var(--microanimation);
    border-radius: var(--border-radius--m);
    pointer-events: none;
  }

  &:focus,
  &:hover {
    color: var(--color--link--hover);
    outline: none;
  }

  &:focus-visible {
    &:before {
      box-shadow: var(--shadow--focus);
    }
  }
}
