@use 'sass:math'; @import 'styles/mixins';
.message {
  display: flex;
  margin: var(--spacing--l) 0;
  padding: var(--spacing--l) var(--spacing--xl);
  border-radius: var(--border-radius--m);
  font-size: var(--font-size--m);
  line-height: var(--line-height--m);
  color: var(--color--text);

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.content {
  flex-grow: 1;
}

.icon {
  $size: 16px;

  position: relative;
  top: 3px;
  width: $size !important;
  height: $size !important;
  margin-right: var(--spacing--m);
}

.default {
  padding-top: 0;
  padding-bottom: 0;
}

.error {
  background-color: var(--color--error--background);

  .icon {
    color: var(--color--error--text);
  }
}

.info {
  background-color: var(--color--info--background);

  .icon {
    color: var(--color--info--text);
  }
}

.success {
  background-color: var(--color--success--background);

  .icon {
    color: var(--color--success--text);
  }
}

.warning {
  background-color: var(--color--warning--background);

  .icon {
    color: var(--color--warning--text);
  }
}
