@use 'sass:math'; @import 'styles/mixins';
.cursors {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
}

.userCursor {
  position: absolute;
  transition: var(--microanimation);
}

.cursor {
  width: 16px;
  height: 16px;
  stroke: white;
  stroke-width: 25;
  margin-right: var(--spacing--s);
}

.name {
  position: absolute;
  display: inline-block;
  top: 50%;
  padding: 2px 6px;
  font-size: var(--font-size--s);
  color: white;
  white-space: nowrap;
  border-radius: var(--border-radius--s);
  text-shadow: 1px 1px 1px rgb(0, 0, 0, 0.9);
}
