@use 'sass:math'; @import 'styles/mixins';
.solidColor {
  $size: 32px;

  display: block;
  position: relative;
  width: $size;
  height: $size;
  transition: var(--microanimation);
  border-radius: var(--border-radius--m);
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }

  &:focus-within {
    box-shadow: var(--shadow--focus);
  }

  &.disabled {
    opacity: 0.5;

    .input {
      cursor: not-allowed;
    }
  }
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  cursor: pointer;
}

.iconContainer {
  $size: 18px;

  position: absolute;
  top: 0;
  right: 0;
  width: $size;
  height: $size;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  border-top-right-radius: inherit;
}

.icon {
  $size: 10px;

  width: $size !important;
  height: $size !important;
  color: white;
}
