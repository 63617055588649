@use 'sass:math'; @import 'styles/mixins';
.appLayout {
  display: flex;
  height: 100%;
  overflow: auto;
}

.sidebar {
  position: relative;
  z-index: 1;
  flex: 0 0 auto;
}

.content {
  position: relative;
  flex: 1;
  overflow: inherit;
}
