@use 'sass:math'; @import 'styles/mixins';
.browsers {
  margin-top: var(--spacing--xs);
  margin-bottom: var(--spacing--m);
  padding-left: var(--spacing--xl);
  padding-inline-start: 0;
  columns: 2;

  @media (max-width: 500px) {
    columns: 1;
  }
}

.browser {
  // prevent focus state from overlapping into another <ul> column
  position: relative;
  top: -2px;
  margin: var(--spacing--xs) 0;
  padding: 2px 0;
  list-style: none;
}

.error {
  margin: var(--spacing--xl) 0;
}

.browserIcon {
  $size: var(--font-size--m);

  position: relative;
  top: 1px;
  display: inline-flex;
  width: $size;
  height: $size;
  margin-right: var(--spacing--s);
}
