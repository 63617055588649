@use 'sass:math'; @import 'styles/mixins';
.modal {
  position: absolute;
  left: 0;
  right: 0;
  outline: none;
  z-index: var(--z-index--modal);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--modal--overlay);
  z-index: var(--z-index--modal-overlay);
}

.content {
  max-width: var(--modal--max-width);
  margin: 0 auto;
  padding: var(--spacing--xxl);

  &.withFooter {
    padding-bottom: 0;
  }

  @include media('<s') {
    max-width: initial;
    padding: 0;
  }
}

.panel {
  @include custom-scrollbars();

  position: relative;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 2 * var(--spacing--xxl));
  padding: 0;
  border: none;

  @include media('<s') {
    width: 100vw;
    height: 100vh;
    max-height: initial;
    border-radius: 0;
  }
}

.closeContainer {
  position: absolute;
  top: var(--spacing--l);
  right: var(--spacing--xl);
  display: flex;
}

.header {
  position: relative;
  flex: 0 0 auto;
  border-bottom: 1px solid var(--color--border);
  padding: var(--spacing--l) var(--spacing--xl);
  padding-right: var(--spacing--xxxl);

  @include media('<s') {
    box-shadow: 0px 1px 10px 0 rgba(0, 0, 0, 0.1);
  }
}

.children {
  flex: 1;
  padding: var(--spacing--xl);
  overflow: auto;

  @include media('<s') {
    /**
     * Allow to scroll vertically a bit more, so that any popups inside the modal (e.g. IconInput)
     * can still fit within the viewport after scrolling down.
     */
    padding-bottom: 300px;
  }
}

.footer {
  flex: 0 0 auto;
  padding: var(--spacing--m) var(--spacing--xl);
  border-top: 1px solid var(--color--border);

  @include media('<s') {
    margin-top: 0;
    box-shadow: 0px -1px 10px 0 rgba(0, 0, 0, 0.1);
  }
}
