@use 'sass:math'; @import 'styles/mixins';
.modal {
  --modal--max-width: 804px;
}

.panel {
  background-color: var(--color--background) !important;

  --color--default--background: var(--color--panel--background);
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 732px;
  margin-top: var(--spacing--xl);

  @include media('<s') {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: var(--spacing--s);
    justify-items: end;
    max-width: initial;
  }
}

.plans {
  gap: var(--spacing--xxl);
}
