@use 'sass:math'; @import 'styles/mixins';
@import 'styles/animations';

.websktopLogo {
  &.brandBlueWhite,
  &.brandWhite,
  &.currentColor,
  &.dynamic {
    path {
      fill: currentColor;
    }
  }

  &.brandBlueWhite,
  &.brandWhite {
    color: white;

    .cursor {
      color: var(--color--brand--red);
    }
  }

  &.brandBlueWhite {
    .ray {
      color: var(--color--brand--blue);
    }
  }

  &.dynamic {
    @include fancy-text(
      $animationSteps: 48,
      $animationElementsCount: 14,
      $delayBetweenLetters: 0.2
    );
  }
}
