@use 'sass:math'; @import 'styles/mixins';
@import 'styles/animations';

.loader {
  $border-width: 3px;

  display: inline-flex;
  width: 32px;
  height: 32px;
  overflow: hidden;
  border: $border-width solid rgba(255, 255, 255, 0.4);
  border-left-color: var(--color--primary--background);
  border-radius: 50%;
  transform: translateZ(0);
  text-indent: -9999em;
  animation: spin 800ms infinite linear;
}
