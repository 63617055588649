@use 'sass:math'; @import 'styles/mixins';
.modal {
  --modal--max-width: 520px;
}

.types {
  display: flex;
  align-items: center;
  gap: var(--spacing--xl);
}

.imageInput {
  --image-input--preview--width: 100% !important;
}

.error {
  margin: var(--spacing--xl) 0;
}

.solidColors {
  margin-top: var(--spacing--s);
}

.textColors {
  display: grid;
  margin: var(--spacing--xl) 0;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing--xl);
}
