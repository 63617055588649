@use 'sass:math'; @import 'styles/mixins';
.accessEntry {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing--m) var(--spacing--l);
  border: 1px solid var(--color--border);

  & + & {
    border-top: none;
  }

  &:first-child {
    border-top-left-radius: var(--border-radius--m);
    border-top-right-radius: var(--border-radius--m);
  }

  &:last-child {
    border-bottom-left-radius: var(--border-radius--m);
    border-bottom-right-radius: var(--border-radius--m);
  }
}

.setting {
  margin-left: var(--spacing--m);
}
