@use 'sass:math'; @import 'styles/mixins';
.labeledInput {
  font-size: var(--font-size--m);
  line-height: var(--line-height--m);

  & + & {
    margin-top: var(--spacing--xl);
  }
}

.label {
  display: flex;
  align-items: center;
}

.required {
  color: var(--color--error--text);
}

.input {
  margin-top: var(--spacing--xs);
}

.description,
.error {
  margin-top: var(--spacing--xs);
  font-size: var(--font-size--s);
  line-height: var(--line-height--s);
}

.description {
  color: var(--color--text);
}

.error {
  color: var(--color--error--text);
}

.info {
  position: relative;
  top: -1px;
  margin-left: var(--spacing--s);
}
