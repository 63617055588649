@use 'sass:math'; @import 'styles/mixins';
.toggle {
  --toggle--size: 20px;
  --toggle--circle-size: 16px;
  --toggle--border-size: 1px;
  --toggle--padding: calc((var(--toggle--size) - var(--toggle--circle-size)) / 2);

  position: relative;
  display: flex;
  align-items: center;
  margin: 0 calc(-1 * var(--spacing--xs));
  border-radius: var(--border-radius--m);
  padding: var(--spacing--xs);
  transition: var(--microanimation);
  cursor: pointer;

  &:focus-within {
    box-shadow: var(--shadow--focus);
  }

  &.disabled {
    opacity: 0.5;

    .input {
      cursor: not-allowed;
    }

    .circle {
      background-color: var(--color--input--text--disabled);
    }
  }

  &.checked {
    .circle {
      left: var(--toggle--circle-size);
    }

    &.fill {
      .circle {
        background-color: var(--color--panel--background);
      }

      .container {
        background-color: var(--color--primary--background);
        border-color: var(--color--primary--border);
      }
    }
  }

  &.large {
    --toggle--size: 28px;
    --toggle--circle-size: 24px;
  }
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}

.label {
  color: var(--color--text);
}

.labelLeft {
  margin-right: var(--spacing--m);
}

.labelRight {
  margin-left: var(--spacing--m);
}

.container {
  flex: 0 0 auto;
  width: calc(
    2 * (var(--toggle--padding) + var(--toggle--circle-size) + var(--toggle--border-size))
  );
  height: calc(var(--toggle--size) + 2 * var(--toggle--border-size));
  padding: var(--toggle--padding);
  background-color: var(--color--panel--background);
  border: var(--toggle--border-size) solid var(--color--border--dark);
  border-radius: calc(var(--toggle--size) / 2);
  transition: var(--microanimation);
}

.circle {
  position: relative;
  left: 0;
  width: var(--toggle--circle-size);
  height: var(--toggle--circle-size);
  border-radius: 50% 50%;
  background-color: var(--color--primary--background);
  transition: var(--microanimation);
}
