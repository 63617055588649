@use 'sass:math'; @import 'styles/mixins';
.websktopIcon {
  $innerShadowSize: 1px;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  width: var(--grid-layout--cell--width);
  padding: $innerShadowSize;
  background-color: var(--grid-layout--background);
  border: 1px solid var(--grid-layout--background);
  transition: var(--microanimation);
  outline: none !important;
  color: inherit !important;
  text-decoration: none !important;

  &:before {
    display: none;
  }

  &:hover::not(.disabled) {
    background-color: var(--grid-layout--background--hover);
    border: 1px solid var(--grid-layout--background--hover);
  }

  &.editing {
    position: absolute;
    z-index: 2;
  }

  &.notInSearch,
  &.cut {
    opacity: 0.35;
  }

  &.notInSearch.cut {
    opacity: 0.2;
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }

  &.selected {
    position: absolute;
    z-index: 1;
  }

  &.selected:not(.editing) {
    background-color: var(--grid-layout--background--selected);
    border: 1px solid var(--grid-layout--background--selected);

    &:hover {
      background-color: var(--grid-layout--background--selected-hover);
      border: 1px solid var(--grid-layout--background--selected-hover);
    }
  }

  &.selectedMany,
  &:not(.selected) {
    .name {
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: var(--grid-layout--ellipsis-lines);
      white-space: normal;
    }
  }
}

.imageContainer {
  @include force-gpu-rendering;

  margin-bottom: var(--grid-layout--image-spacing);
  filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.4));
}

.name,
.nameInput {
  width: 100%;
  font-family: var(--font-family);
  font-size: var(--grid-layout--font-size);
  line-height: var(--grid-layout--line-height);
  text-align: center;
  word-break: break-word;
}

.name {
  @include force-gpu-rendering;

  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  padding: 0 1px;
  text-shadow: inherit;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.5));
}

.nameInput {
  width: 82px;
  padding: 0px 3px;
  border: 1px solid var(--grid-layout--border);
  border-radius: 0;
  background-color: var(--grid-layout--input--background);
  color: var(--grid-layout--input--text);
  resize: none;
  overflow: hidden;

  &:focus {
    outline: none;
  }
}
