@use 'sass:math'; @import 'styles/mixins';
$button-size: 50px;

.button {
  width: $button-size;
  height: $button-size;
  border-radius: 50%;
  transition: var(--microanimation);
}

.menuOpen {
  transform: rotate(90deg);
}

:export {
  BUTTON_SIZE: $button-size;
}
