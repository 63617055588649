@use 'sass:math'; @import 'styles/mixins';
:export {
  OFFSET: 4px;
  PREVENT_OVERFLOW: 10px;
}

.button {
  --triangle--size: 12px;

  font-weight: bold;
  padding: var(--spacing--s) var(--spacing--l) !important;

  &.small {
    --triangle--size: 8px;

    padding: var(--spacing--xs) var(--spacing--m) !important;
  }

  &.large {
    --triangle--size: 16px;

    padding: var(--spacing--l) var(--spacing--xxl) !important;

    .triangle {
      margin-left: var(--spacing--l);
    }
  }

  &.open {
    &.danger {
      background-color: var(--color--danger--background--active);
      color: var(--color--danger--text--active);
    }

    &.default {
      background-color: var(--color--default--background--active);
      color: var(--color--default--text--active);
    }

    &.primary {
      background-color: var(--color--primary--background--active);
      color: var(--color--primary--text--active);
    }
  }
}

.dropdown {
  min-width: 200px;
  max-width: 100vw;
  z-index: var(--z-index--dropdown-button-overlay);

  &.closed {
    display: none;
  }
}

.triangle {
  display: inline-flex;
  align-items: center;
  width: var(--triangle--size) !important;
  height: var(--triangle--size) !important;
  margin-left: var(--spacing--s);
}

.overlay {
  position: fixed;
  inset: 0px;
  z-index: var(--z-index--context-menu-overlay);
  pointer-events: none;
}

.modal {
  pointer-events: auto;
}
