@use 'sass:math'; @import 'styles/mixins';
.unnamed {
  font-style: italic;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.icon {
  flex: 0 0 auto;
}
