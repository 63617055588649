@use 'sass:math'; @import 'styles/mixins';
.ppms_cm_popup_overlay.ppms_cm_popup_overlay {
  &,
  &:focus,
  &:hover {
    border-top-right-radius: var(--border-radius--l) !important;
    right: auto !important;
    width: auto !important;
    max-width: 480px !important;
    margin: 0 auto !important;
    left: 0 !important;

    @include media('<m') {
      border-top-right-radius: 0 !important;
    }
  }

  .ppms_cm_popup_wrapper.top {
    margin-top: 0 !important;
  }

  .ppms_cm_language_select_wrapper {
    display: none !important;
  }

  .ppms_cm_main_content {
    &,
    &:focus,
    &:hover {
      display: block !important;
    }
  }

  .ppms_agree_button_wrapper {
    &,
    &:focus,
    &:hover {
      margin-top: var(--spacing--l) !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      flex-direction: row !important;
      justify-content: flex-start !important;
      height: auto !important;
    }
  }

  .ppms_cm_agree-to-all {
    &,
    &:focus,
    &:hover {
      margin-right: var(--spacing--l) !important;
    }
  }

  .ppms_cm_reject-all {
    &,
    &:focus,
    &:hover {
      margin-right: 0 !important;
      background-color: #666 !important;
    }
  }

  .ppms_cm_paragraph {
    &,
    &:focus,
    &:hover {
      padding-right: 0 !important;
    }
  }

  .ppms_cm_popup {
    &,
    &:focus,
    &:hover {
      padding: var(--spacing--xl) !important;

      @include media('<m') {
        padding: var(--spacing--m) var(--spacing--l) !important;
      }
    }
  }

  .ppms_cm_language_select_wrapper {
    left: var(--spacing--xl) !important;

    @include media('<m') {
      left: var(--spacing--l) !important;
    }
  }

  .ppms_cm_footer {
    display: none !important;
  }
}
