@use 'sass:math'; @import 'styles/mixins';
.tooltipContent {
  text-align: left;

  &,
  p {
    color: black;
  }
}

.permission {
  $size: 1px;

  width: $size;
  height: $size;
  position: fixed;
  top: -1px;
  left: 130px;
  user-select: none;
  pointer-events: none;
  background-color: transparent;
  opacity: 0;
}
