@use 'sass:math'; @import 'styles/mixins';
.modal {
  --modal--max-width: 600px;
}

.card {
  display: grid;
  grid-template-columns: 182px auto;
  grid-gap: var(--spacing--xl);
  align-items: center;
  margin-bottom: var(--spacing--xl);

  @include media('<xs') {
    grid-template-columns: 1fr;
    justify-items: center;
  }
}

.image {
  object-fit: cover;
}

.features {
  margin: var(--spacing--xl) 0;
}

.tooltip {
  max-width: 220px;
}

.infoIcon {
  position: relative;
  top: 1px;
  width: var(--font-size--m) !important;
  height: var(--font-size--m) !important;
  margin-left: var(--spacing--s);
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--spacing--xxl);
}

.action {
  & + & {
    margin-left: var(--spacing--xxl);

    @include media('<s') {
      margin-left: var(--spacing--xl);
    }
  }
}
