@use 'sass:math'; @import 'styles/mixins';
.plans {
  display: grid;
  grid-template-columns: repeat(2, min-content);
  gap: var(--spacing--xxxl);
  font-family: var(--font-family);

  @include media('<s') {
    grid-template-columns: 1fr;
  }

  @include media('<xs') {
    width: 100%;
  }
}

.plan {
  align-self: flex-start;

  @include media('<s') {
    justify-self: center;
  }

  @include media('<xs') {
    width: 100%;
  }
}
