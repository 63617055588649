@use 'sass:math'; @import 'styles/mixins';
.price {
  font-size: var(--font-size--xxxl);
  line-height: var(--line-height--xxxl);
  font-weight: bold;
}

.usd {
  margin-right: var(--spacing--xs);
}

.eur {
  margin-left: var(--spacing--xs);
}

.cents {
  font-size: var(--font-size--xl);
  line-height: var(--line-height--xl);
  vertical-align: super;
}
