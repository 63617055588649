@use 'sass:math'; @import 'styles/mixins';
@import 'styles/animations';

.splashLoader {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  height: 100px;
  margin: 0 var(--spacing--xxl);
  opacity: 0;
  animation: reveal var(--microanimation--duration);
  animation-delay: var(--microanimation--duration);
  animation-fill-mode: forwards;
}
