@use 'sass:math'; @import 'styles/mixins';
$closeButtonMargin: var(--spacing--s);
$closeButtonWidth: 32px;

.notification {
  position: relative;
  display: flex;
  padding: var(--spacing--l) var(--spacing--l);
  border-radius: var(--border-radius--l);
  box-shadow: var(--shadow--drop);
  background-color: var(--notification--background);
  color: var(--notification--text);

  &.error {
    .icon {
      color: var(--color--error--text);
    }
  }

  &.info {
    .icon {
      color: var(--color--info--text);
    }
  }

  &.success {
    .icon {
      color: var(--color--success--text);
    }
  }

  &.warning {
    .icon {
      color: var(--color--warning--text);
    }
  }
}

.iconContainer {
  margin-right: var(--spacing--m);
  flex: 0 0 auto;
}

.icon {
  $size: 24px;

  position: relative;
  top: 1px;
  width: $size !important;
  height: $size !important;
}

.content {
  flex: 1;
}

.title {
  flex: 1;
  font-size: var(--font-size--l);
  line-height: var(--line-height--l);
  padding-right: calc(#{$closeButtonWidth} + #{$closeButtonMargin});
}

.closeButton {
  position: absolute;
  top: $closeButtonMargin;
  right: $closeButtonMargin;
  flex: 0 0 auto;
}
