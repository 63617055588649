@use 'sass:math'; @import 'styles/mixins';
$searchIconSize: 13px;

:export {
  OFFSET: 6px;
  PREVENT_OVERFLOW: 10px;
}

.searchDropdown {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 200px;

  &:focus-within {
    .input {
      padding-right: var(--spacing--s);
    }

    .focusHotkey {
      display: none;
    }
  }
}

.input {
  width: 100%;
  position: relative;
  padding: var(--spacing--xs) var(--spacing--s);
  padding-left: calc(var(--spacing--s) + var(--spacing--xs) + #{$searchIconSize});
  padding-right: 27px;
  background-color: var(--color--input--background);
  border-radius: var(--border-radius--m);
  border: 1px solid var(--color--border);
  font-size: 13px;
  line-height: var(--line-height--s);
  color: var(--color--input--text);
  transition: var(--microanimation);
  transition-property: border-color, box-shadow, background-color, color;

  &:focus,
  &:hover {
    border-color: var(--color--input--border--active);
  }

  &:focus {
    z-index: 1;
    outline: none;
  }

  &:focus-visible {
    box-shadow: var(--shadow--focus);
  }

  &::placeholder {
    color: var(--color--text--secondary);
  }
}

.inputIcon {
  position: absolute;
  top: 50%;
  left: var(--spacing--s);
  z-index: 1;
  width: $searchIconSize !important;
  height: $searchIconSize !important;
  color: var(--color--text--secondary);
  user-select: none;
  pointer-events: none;
  transform: translateY(-50%);
}

// increase specificity
.searchDropdown {
  .focusHotkey {
    $size: 18px;

    position: absolute;
    width: $size;
    height: $size;
    top: 50%;
    right: 5px;
    z-index: 1;
    font-size: 11px;
    line-height: initial;
    box-shadow: none;
    border: 1px solid var(--color--border);
    user-select: none;
    pointer-events: none;
    transform: translateY(-50%);
  }
}

.results {
  @include custom-scrollbars();

  z-index: 2;
  width: 300px;
  max-height: 500px;
  padding: var(--spacing--xs) 0;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--color--panel--background);
  border-bottom-right-radius: var(--border-radius--m);
  border-bottom-left-radius: var(--border-radius--m);
  box-shadow: var(--shadow--drop);

  &.closed {
    display: none;
  }
}

.message {
  width: 100%;
  padding: var(--spacing--s) var(--spacing--l);
  color: var(--color--text--primary);
  font-size: var(--font-size--s);
  line-height: var(--line-height--s);
  text-align: center;
}
