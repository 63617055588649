@use 'sass:math'; @import 'styles/mixins';
.badge {
  @include button-reset;

  display: inline-flex;
  align-items: center;
  padding: 1px 8px;
  border: 1px solid transparent;
  border-radius: var(--border-radius--l);
  font-size: var(--font-size--m);
  line-height: var(--line-height--m);

  &:focus-visible {
    box-shadow: var(--shadow--focus);
  }

  &.small {
    padding: 1px 6px;
    font-size: var(--font-size--s);
    line-height: var(--line-height--s);

    .icon {
      $size: var(--font-size--s);

      width: $size !important;
      height: $size !important;
    }
  }

  &.brandRed {
    border-color: var(--color--brand--red);
    background-color: var(--color--brand--red);
    color: white;
  }

  &.brandBlue {
    border-color: var(--color--brand--blue);
    background-color: var(--color--brand--blue);
    color: white;
  }

  &.danger {
    border-color: var(--color--danger--text);
    background-color: var(--color--danger--background);
    color: var(--color--danger--text);
  }

  &.default {
    border-color: var(--color--default--text);
    background-color: var(--color--default--background);
    color: var(--color--default--text);
  }

  &.error {
    border-color: var(--color--error--text);
    background-color: var(--color--error--background);
    color: var(--color--error--text);
  }

  &.info {
    border-color: var(--color--info--text);
    background-color: var(--color--info--background);
    color: var(--color--info--text);
  }

  &.primary {
    border-color: var(--color--primary--background--text);
    background-color: var(--color--primary--background);
    color: var(--color--primary--text);
  }

  &.success {
    border-color: var(--color--success--text);
    background-color: var(--color--success--background);
    color: var(--color--success--text);
  }

  &.warning {
    border-color: var(--color--warning--text);
    background-color: var(--color--warning--background);
    color: var(--color--warning--text);
  }
}

.clickable {
  cursor: pointer;
}

.icon {
  $size: var(--font-size--m);

  width: $size !important;
  height: $size !important;
  margin-right: var(--spacing--s);
}
