@use 'sass:math'; @import 'styles/mixins';
.key {
  $size: 20px;
  $shadowColor: #d8d8d8;
  $backgroundColor: #fafafa;

  display: inline-block;
  height: $size;
  padding: 0 var(--spacing--xs);
  border: 1px solid $shadowColor;
  border-bottom: 1px solid $backgroundColor;
  border-radius: var(--border-radius--m);
  background-color: $backgroundColor;
  box-shadow: 0px 2px 0px 1px $shadowColor;
  font-family: var(--font-family--code);
  font-size: 14px;
  line-height: $size;
  vertical-align: baseline;
  text-align: center;
  white-space: nowrap;
}
