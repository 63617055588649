@use 'sass:math'; @import 'styles/mixins';
.content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.panel {
  flex: 1;
  height: 100%;
}
