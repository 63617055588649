@use 'sass:math'; @import 'styles/mixins';
.actions {
  margin: var(--spacing--xl) 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @include media('>xs') {
    display: flex;
  }
}

.action {
  @include media('>xs') {
    & + & {
      margin-left: var(--spacing--xl);
    }
  }

  @include media('<xs') {
    width: 100%;
    & + & {
      margin-top: var(--spacing--xl);
    }
  }
}
