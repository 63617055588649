@use 'sass:math'; @import 'styles/mixins';
.panel {
  margin: var(--spacing--xl) 0;
  padding: var(--spacing--xl);
  border: 1px solid var(--color--panel--border);
  border-radius: var(--border-radius--l);
  box-shadow: var(--shadow);
  overflow: hidden;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.default {
    background-color: var(--color--panel--background);
    color: var(--color--panel--text);
  }

  &.error {
    background-color: var(--color--error--background);
    color: var(--color--error--text);
  }

  &.info {
    background-color: var(--color--info--background);
    color: var(--color--info--text);
  }

  &.success {
    background-color: var(--color--success--background);
    color: var(--color--success--text);
  }
}
