@use 'sass:math'; @import 'styles/mixins';
.error {
  margin: var(--spacing--xl) 0;
}

.inviteInput {
  margin: var(--spacing--l) 0;
}

.label {
  font-size: var(--font-size--m);
  line-height: var(--line-height--m);
  margin: var(--spacing--xs) 0;
}

.users {
  margin-top: var(--spacing--l);
}

.moreUsers {
  display: flex;
  justify-content: center;
  margin-top: var(--spacing--s);
}

.sharingLink {
  margin-top: var(--spacing--l);
}

.footer {
  justify-content: flex-end !important;

  &.twoButtons {
    justify-content: space-between !important;
  }
}
