@use 'sass:math'; @import 'styles/mixins';
.statusBar {
  @include custom-scrollbars();

  --color--border: var(--status-bar--border-color);

  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--status-bar--height);
  background-color: var(--status-bar--background);
  color: var(--status-bar--text);
  box-shadow: var(--shadow);
  font-size: var(--font-size--s);
  line-height: var(--line-height--s);
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
