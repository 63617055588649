@use 'sass:math'; @import 'styles/mixins';
.tooltip {
  max-width: 400px;
}

.actions {
  display: inline-flex;
  justify-content: center;
  margin-left: var(--spacing--m);
}

.link {
  & + & {
    margin-left: var(--spacing--l);
  }
}
