@use 'sass:math'; @import 'styles/mixins';
.fileInput {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid var(--color--input--border);
  background-color: var(--color--input--background);
  border-radius: var(--border-radius--m);

  &:focus-within {
    box-shadow: var(--shadow--focus);
    outline: none;
  }

  &:focus,
  &:focus-within,
  &:hover {
    border-color: var(--color--input--border--active);
  }

  &.hasValue {
    .value {
      color: var(--color--input--text);
    }
  }

  &.disabled {
    border-color: var(--color--input--border--disabled);

    .value {
      background-color: var(--color--input--background--disabled);
      color: var(--color--input--text--disabled);
    }
  }
}

.value {
  @include ellipsis;

  flex: 1;
  padding: var(--spacing--s) var(--spacing--m);
  background-color: var(--color--input--background);
  color: var(--color--text--secondary);
  border-top-right-radius: var(--border-radius--m);
  border-bottom-right-radius: var(--border-radius--m);
}

.addonAfter {
  flex: 0 0 auto;
  border: none;
  border-right: 1px solid var(--color--input--border);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  background-color: var(--color--input--background);

  &[disabled] {
    cursor: not-allowed;
    background-color: var(--color--input--background--disabled);
  }

  &::placeholder {
    color: var(--color--text--secondary);
  }
}
