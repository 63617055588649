@use 'sass:math'; @import 'styles/mixins';
:export {
  OFFSET: 4px;
  PREVENT_OVERFLOW: 10px;
}

.popup {
  max-width: 100vw;
  z-index: 1;
}

.overlay {
  position: fixed;
  inset: 0px;
  z-index: var(--z-index--popup-overlay);
  pointer-events: none;
}

.modal {
  pointer-events: auto;
}
