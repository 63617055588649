@use 'sass:math'; @import 'styles/mixins';
:export {
  OFFSET: 4px;
  PREVENT_OVERFLOW: 10px;
}

.customColor {
  $size: 42px;

  width: $size !important;
  height: $size !important;
  transform: none !important;
}

.dropdown {
  border-radius: var(--border-radius--l);
  box-shadow: var(--shadow--drop);
  z-index: var(--z-index--dropdown-button-overlay);

  &.closed {
    display: none;
  }
}

.overlay {
  position: fixed;
  inset: 0px;
  z-index: var(--z-index--context-menu-overlay);
  pointer-events: none;
}

.modal {
  pointer-events: auto;
}

.infoIcon {
  margin-left: var(--spacing--s);
}
