@use 'sass:math'; @import 'styles/mixins';
.infoIcon {
  $size: 16px;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: $size;
  height: $size;
  color: var(--color--primary--background);

  &:focus-visible {
    .icon {
      box-shadow: var(--shadow--focus);
    }
  }
}

.icon {
  width: inherit !important;
  height: inherit !important;
  color: inherit;
  border-radius: 50%;
}
