@use 'sass:math'; @import 'styles/mixins';
@import 'styles/animations';

.wrapper {
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.content {
  @include custom-scrollbars();

  flex: 1;
  overflow: auto;
  outline: 0;
  scroll-padding-top: var(--status-bar--height);
  scroll-padding-left: var(--sidebar--width);
  background-color: #41526c;
}

.statusBar {
  flex: 0 0 auto;
}

.meta {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}

.metaItem {
  margin-left: var(--spacing--l);
}

.indicators {
  display: flex;
}

.indicator {
  & + & {
    margin-left: var(--spacing--s);
  }
}

.buttons {
  border-left: 1px solid var(--color--border);
}

.plusButton {
  position: fixed;
  right: var(--spacing--xl);
  bottom: var(--spacing--xl);

  @include media('<s') {
    right: var(--spacing--l);
    bottom: var(--spacing--l);
  }
}
