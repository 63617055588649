@use 'sass:math'; @import 'styles/mixins';
.dragged {
  transition: none;
  visibility: hidden;
}

.isOver {
  transition: none;
}

.canDropItems {
  background-color: var(--grid-layout--background--hover);

  &.itemsOver {
    color: var(--color--success--text);
    background-color: var(--color--success--background);
  }
}
