@use 'sass:math'; @import 'styles/mixins';
.textInput {
  &.withAddonLeft {
    .input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &.withAddonRight {
    .input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.content {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid var(--color--input--border);
  border-radius: var(--border-radius--m);

  &:focus,
  &:focus-within,
  &:hover {
    border-color: var(--color--input--border--active);
  }
}

.addon {
  position: relative;
  flex: 0 0 auto;

  &:focus-within {
    z-index: 1;
  }
}

.addonLeft {
  border-right: 1px solid var(--color--input--border);
  border-top-left-radius: var(--border-radius--m);
  border-bottom-left-radius: var(--border-radius--m);

  > * {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.addonRight {
  border-left: 1px solid var(--color--input--border);
  border-top-right-radius: var(--border-radius--m);
  border-bottom-right-radius: var(--border-radius--m);

  > * {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.input {
  width: 100%;
  position: relative;
  padding: var(--spacing--s) var(--spacing--m);
  background-color: var(--color--input--background);
  border-radius: var(--border-radius--m);
  border: none;
  font-size: var(--font-size--m);
  line-height: var(--line-height--m);
  color: var(--color--input--text);
  transition: var(--microanimation);

  &:focus,
  &:hover {
    border-color: var(--color--input--border--active);
  }

  &:focus {
    z-index: 1;
    outline: none;
  }

  &:focus-visible {
    box-shadow: var(--shadow--focus);
  }

  &[disabled] {
    border-color: var(--color--input--border--disabled);
    background-color: var(--color--input--background--disabled);
    color: var(--color--input--text--disabled);
  }

  &::placeholder {
    color: var(--color--text--secondary);
  }

  .addonLeft + & {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .addonRight + & {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
