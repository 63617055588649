@use 'sass:math'; @import 'styles/mixins';
@import 'styles/animations';

.dropTarget {
  width: var(--grid-layout--cell--width);
  height: var(--grid-layout--cell--height);

  rect {
    stroke: var(--color--text);
    stroke-dasharray: 4;
    animation: dash 3500ms infinite linear forwards;
  }
}
