@use 'sass:math'; @import 'styles/mixins';
.link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: var(--spacing--xs) 0;
  width: calc(var(--sidebar--width) - 2 * var(--spacing--m));
  height: var(--sidebar--item--height);
  padding: 0 var(--spacing--m);
  border-radius: var(--border-radius--m);
  color: var(--sidebar--text);
  transition: var(--microanimation);
  transition-property: background-color, color, border-color, box-shadow;
  cursor: pointer;
  outline: none;
  text-decoration: none !important;
  background-color: var(--sidebar--background);

  &:active {
    top: 1px;
  }

  &:hover {
    background-color: var(--sidebar--background--hover);
  }

  &.active {
    background-color: var(--sidebar--background--active);
  }

  &:focus-visible {
    box-shadow: var(--shadow--focus);
  }

  &:focus-visible {
    transition: none;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.active,
  &:focus,
  &:hover {
    &:before {
      content: ' ';
      position: absolute;
      left: calc(-1 * var(--spacing--m));
      height: 100%;
      width: 3px;
      border-top-right-radius: var(--border-radius--m);
      border-bottom-right-radius: var(--border-radius--m);
    }
  }

  &:focus,
  &:hover {
    &:before {
      background-color: white;
    }
  }

  &.active {
    &:before {
      background-color: var(--color--highlight);
    }
  }
}

.icon {
  flex: 0 0 auto;
  width: var(--sidebar--item--icon--size);
  height: var(--sidebar--item--icon--size);
}

.name {
  @include ellipsis;

  margin-left: var(--spacing--l);
  flex: 1;
  text-align: left;
}
