@use 'sass:math'; @import 'styles/mixins';
@import 'styles/animations';

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: var(--sidebar--width);
  background-color: var(--sidebar--background);
  color: var(--sidebar--text);
}

.logoIcon {
  width: var(--sidebar--websktop--icon--size);
  height: var(--sidebar--websktop--icon--size);
}

.logo {
  height: 40px;
}

.group {
  @include custom-scrollbars();

  flex: 1;
  padding: var(--spacing--m);
  overflow-y: auto;
  overflow-x: hidden;

  & + & {
    margin-top: var(--spacing--l);
  }
}

.buttons {
  flex: 0 0 auto;
}

.content {
  flex: 1;
}

.action {
  user-select: none;

  &:focus-visible {
    box-shadow: var(--shadow--focus);
  }

  &:before {
    display: none;
  }
}

.createNewWebsktop {
  @include button-reset;

  margin-top: var(--spacing--m);
  padding: var(--spacing--s);
  height: auto;
  width: 100%;
  text-align: center;
  font-size: var(--font-size--s);
  cursor: pointer;
}
