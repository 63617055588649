@use 'sass:math'; @import 'styles/mixins';
$radio-size: 16px;
$radio-inner-border: 2px;
$radio-inner-size: $radio-size - 2 * $radio-inner-border;
$radio-box-size: $radio-size + 2 * $radio-inner-border;

.radio {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 calc(-1 * var(--spacing--xs));
  padding: var(--spacing--xs);
  border-radius: var(--border-radius--m);
  transition: var(--microanimation);
  cursor: pointer;

  &:focus-within {
    box-shadow: var(--shadow--focus);
  }

  &:hover,
  &.checked {
    .icon {
      box-shadow: var(--shadow);
    }
  }

  &.disabled {
    opacity: 0.5;

    .input {
      cursor: not-allowed;
    }

    .icon {
      &,
      &:after {
        background-color: transparent;
        border-color: var(--color--input--text--disabled);
      }
    }
  }

  &.checked {
    .icon {
      &:after {
        background-color: var(--color--primary--background);
      }
    }

    &.disabled {
      .icon {
        &:after {
          background-color: var(--color--input--text--disabled);
        }
      }
    }
  }
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  cursor: pointer;
}

.icon {
  flex: 0 0 auto;
  position: relative;
  width: $radio-box-size;
  height: $radio-box-size;
  border-radius: 50%;
  border: $radio-inner-border solid var(--color--primary--background);
  box-shadow: var(--shadow--null);
  pointer-events: none;

  &:after {
    content: ' ';
    position: absolute;
    top: $radio-inner-border;
    left: $radio-inner-border;
    width: $radio-inner-size;
    height: $radio-inner-size;
    background-color: transparent;
    border-radius: 50%;
    transition: var(--microanimation);
  }
}

.content {
  position: relative;
  top: 1px;
  flex: 1;
  margin-left: var(--spacing--s);
}
