@use 'sass:math'; @import 'styles/mixins';
.breadcrumbs,
.crumbs {
  position: relative;
  display: flex;
  align-items: center;
  height: var(--status-bar--height);
}

.actions {
  border-right: 1px solid var(--color--border);
  border-left: 1px solid var(--color--border);
}

.folderUp {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--status-bar--height);
  height: var(--status-bar--height);
  background-color: var(--status-bar--button--background);
  transition: var(--microanimation);

  @include all-link-states {
    color: var(--status-bar--button--text);
    text-decoration: none;
  }

  &.disabled {
    cursor: not-allowed;
    color: var(--color--text--disabled);
  }

  &:focus:not(.disabled),
  &:hover:not(.disabled) {
    background-color: var(--status-bar--button--background--hover);
  }
}

.name {
  @include ellipsis;

  display: block;
  max-width: 120px;
}

.unnamed {
  $ellipsisItalicsCompensation: 2px;

  padding-right: $ellipsisItalicsCompensation;
  margin-right: -$ellipsisItalicsCompensation;
  font-style: italic;
}

.icon {
  width: var(--status-bar--icon--size) !important;
  height: var(--status-bar--icon--size) !important;
}

.websktopIcon {
  border-radius: var(--border-radius--s);
}

.websktopIcon,
.folderIcon {
  margin-right: var(--spacing--s);
  position: relative;
  top: -1px;

  @include media('<s') {
    display: none;
  }
}
