@use 'sass:math'; @import 'styles/mixins';
.contextMenu {
  width: 400px;
  max-width: 100vw;
  z-index: 1;
}

.overlay {
  position: fixed;
  inset: 0px;
  z-index: var(--z-index--context-menu-overlay);
  pointer-events: none;
}

.modal {
  pointer-events: auto;
}
