@use 'sass:math'; @import 'styles/mixins';
.row {
  display: flex;
  gap: var(--grid-layout--spacing--column);
  margin-bottom: var(--grid-layout--spacing--row);

  &:last-child {
    margin-bottom: 0;
  }
}

.cell {
  position: relative;
  display: inline-block;
  flex: 0 0 var(--grid-layout--cell--width);
  width: var(--grid-layout--cell--width);
  height: var(--grid-layout--cell--height);
}
