@use 'sass:math'; @import 'styles/mixins';
.feature {
  display: flex;
  font-size: var(--font-size--m);
  line-height: var(--line-height--m);

  & + & {
    margin-top: var(--spacing--l);
  }
}

.check {
  position: relative;
  top: -1px;
  width: var(--font-size--m) !important;
  height: var(--font-size--m) !important;
  margin-top: calc((var(--line-height--m) - var(--font-size--m)) / 2);
  margin-right: var(--spacing--s);
  color: var(--color--success--text);
}
