@use 'sass:math'; @import 'styles/mixins';
.ssoLink {
  $size: 40px;

  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--spacing--m) var(--spacing--xl);
  border: 1px solid var(--color--border);
  border-radius: var(--border-radius--l);
  background-color: white;
  box-shadow: var(--shadow);
  transition: var(--microanimation);

  @include all-link-states {
    color: var(--color--text);
    text-decoration: none;
  }

  &:focus,
  &:hover {
    box-shadow: var(--shadow--focus);
  }

  &:hover {
    background-color: var(--color--default--background--hover);
  }
}

.logo {
  $size: 24px;

  width: $size;
  height: $size;
}

.label {
  margin-left: var(--spacing--m);
}
