@use 'sass:math'; @import 'styles/mixins';
.cueContent {
  max-width: 240px;
}

.menuButton {
  $size: 24px;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: -3px 2px;
  width: $size;
  height: $size;
  border-radius: 50%;
  border-color: var(--color--primary--border);
  background-color: var(--color--primary--background);
  color: var(--color--primary--text);
}
