@use 'sass:math'; @import 'styles/mixins';
.plan {
  width: 330px;
  min-height: 260px;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding: var(--spacing--xxl) !important;

  @include media('<xs') {
    width: 100%;
  }

  &.withDiscount {
    .price {
      color: var(--color--success--text);
    }
  }
}

.name {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: var(--font-size--xl);
  line-height: var(--line-height--xl);
  text-align: center;
}

.yourPlan {
  margin-left: var(--spacing--s);
}

.price {
  position: relative;
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-top: var(--spacing--xl);
  margin-bottom: var(--spacing--xs);
}

.discount {
  position: absolute;
  top: -4px;
  left: calc(50% + 20px);
}

.billingCycle,
.billing {
  color: #8e8e93;
}

.billingCycle {
  margin-left: var(--spacing--xs);
  font-size: var(--font-size--m);
  line-height: var(--line-height--m);
}

.billing {
  font-size: var(--font-size--s);
  line-height: var(--line-height--s);
  text-align: center;
}

.divider {
  margin: var(--spacing--xxl) 0;
}

.action {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--spacing--xxl);
}
