@use 'sass:math'; @import 'styles/mixins';
.upgradePlanPromo {
  border-radius: var(--border-radius--s) !important;
  box-shadow: none !important;
}

.content {
  text-align: center;
  font-weight: bold;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: var(--spacing--l);
}
